import React from "react";
import jsonData from "./data/jsondata.js";
import { Link } from "react-router-dom";

function NavBar() {
  const inlineStyle = {
    zIndex: "9",
  };

  return (
    <div className="container-fluid nav-bar p-0 navbar-fixed-top sticky-top">
      <div
        className="container-lg position-relative p-0 px-lg-3"
        style={inlineStyle}
      >
        <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
          <Link to="/" className="navbar-brand">
            <h1 className="m-0 text-primary">
              <span className="text-dark">{jsonData.companyName1}</span>
              {jsonData.companyName2}
            </h1>
            <p
              className="m-0 text-dark"
              style={{ fontSize: "12px", textAlign: "left" }}
            >
              {jsonData.tagline}
            </p>
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between px-3"
            id="navbarCollapse"
          >
            <div className="navbar-nav ml-auto py-0">
              <Link to="#header-carousel" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="#contact" className="nav-item nav-link">
                Enquire
              </Link>
              <Link to="#about" className="nav-item nav-link">
                About
              </Link>
              <Link to="#destination" className="nav-item nav-link">
                Destinations
              </Link>
              <Link to="#service" className="nav-item nav-link">
                Services
              </Link>
              <Link to="#testimonial" className="nav-item nav-link">
                Testimonial
              </Link>

              {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu border-0 rounded-0 m-0">
                  <a href="blog.html" className="dropdown-item">
                    Blog Grid
                  </a>
                  <a href="single.html" className="dropdown-item">
                    Blog Detail
                  </a>
                  <a href="destination.html" className="dropdown-item">
                    Destination
                  </a>
                  <a href="guide.html" className="dropdown-item">
                    Travel Guides
                  </a>
                  <a href="package.html" className="nav-item nav-link">
                Tour Packages
              </a>
                  <a href="testimonial.html" className="dropdown-item">
                    Testimonial
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
