import React from "react";
import jsonData from "./data/jsondata";

function Feature() {
  return (
    <div id="feature" className="container-fluid pb-5">
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex mb-4 mb-lg-0">
              <div
                className="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary mr-3"
                style={{ height: "100px", width: "100px" }}
              >
                <i className="fa fa-2x fa-money-check-alt text-white"></i>
              </div>
              <div className="d-flex flex-column">
                <h5 className="">{jsonData.feature[0].title}</h5>
                <p className="m-0">{jsonData.feature[0].description}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4 mb-lg-0">
              <div
                className="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary mr-3"
                style={{ height: "100px", width: "100px" }}
              >
                <i className="fa fa-2x fa-award text-white"></i>
              </div>
              <div className="d-flex flex-column">
                <h5 className="">{jsonData.feature[1].title}</h5>
                <p className="m-0">{jsonData.feature[1].description}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex mb-4 mb-lg-0">
              <div
                className="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary mr-3"
                style={{ height: "100px", width: "100px" }}
              >
                <i className="fa fa-2x fa-globe text-white"></i>
              </div>
              <div className="d-flex flex-column">
                <h5 className="">{jsonData.feature[2].title}</h5>
                <p className="m-0">{jsonData.feature[2].description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
