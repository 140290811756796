import React from "react";
import about from "./img/about.jpg";
import about1 from "./img/about-1.jpg";
import about2 from "./img/about-2.jpg";
import jsonData from "./data/jsondata";

function About() {
  return (
    <div id="about" className="container-fluid py-1 justify-content-center">
      <div
        className="container justify-content-center pt-5 pb-3"
        style={{ maxWidth: "800px" }}
      >
        {/* <div className="col-lg-6" style={{ minHeight: "500px" }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100"
                src={about}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div> */}
        <div className="about-text bg-white p-4">
          <h6
            className="text-primary text-uppercase"
            style={{ letterSpacing: "5px" }}
          >
            About Us
          </h6>
          <h1 className="mb-3">{jsonData.aboutUs.title}</h1>
          <p>{jsonData.aboutUs.description}</p>
          <div className="row mb-4">
            <div className="col-6">
              <img className="img-fluid" src={about1} alt="" />
            </div>
            <div className="col-6">
              <img className="img-fluid" src={about2} alt="" />
            </div>
          </div>
          <a href="" className="btn btn-primary mt-1">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
