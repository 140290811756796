import { Modal, Form, Button } from "react-bootstrap";
import React, { Component } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Loader from "./Loader";

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      from: props.from,
      to: props.to,
      departureDate: props.departureDate,
      travelMode: "",
      number: "",
      name: "",
      phone: "",
      email: "",
    };
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    console.log(process.env.REACT_APP_SERVICE_ID);
    console.log(process.env.REACT_APP_TEMPLATE_ID);
    console.log(process.env.REACT_APP_USER_ID);
  }

  handleSubmit = async (event) => {
    this.setState({
      loading: true,
    });
    const service_id = "service_iw4ps51";
    const template_id = "template_ztnjup6";
    const userIdPublicKey = "FEMz1uEja_CpZVix0";

    event.preventDefault();

    await emailjs
      .send(service_id, template_id, this.state, userIdPublicKey)
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);

          Swal.fire({
            icon: "success",
            title: "Your enquiry is submitted, We will get back to you soon",
          });
        },
        function (error) {
          console.log("FAILED...", error);
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
          });
        }
      );
    this.props.toggleModal();
    this.setState({
      loading: false,
    });
  };

  render() {
    const { from, to, departureDate, travelMode, number, name, phone, email } =
      this.state;
    return (
      <div>
        <Loader show={this.state.loading} />
        <Modal
          backdrop="static"
          show={this.props.show}
          onHide={this.props.toggleModal}
        >
          <Modal.Header>
            <h5>Please give us more details to serve you better</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={this.props.toggleModal}
            >
              &times;
            </button>
            {/* <Modal.closeButton>
              <button type="button" class="close" data-dismiss="modal">
      &times;
</button> <Modal.closeButton /> */}
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>From</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="from"
                  type="text"
                  className="form-control p-4"
                  placeholder="Eg. Mumbai/Chennai"
                  value={from}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>To</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="to"
                  type="text"
                  className="form-control p-4"
                  placeholder="Eg. Delhi/Manali"
                  value={to}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Depart Date</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  value={departureDate}
                  name="departureDate"
                  type="date"
                  className="form-control p-4 datetimepicker-input"
                  placeholder="Depart Date"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>No. of Travellers</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="number"
                  type="number"
                  className="form-control p-4"
                  placeholder="Eg. 2/3/4"
                  value={number}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Mode of travel</Form.Label>

                <select
                  name="travelMode"
                  value={travelMode}
                  placeholder="Select Mode"
                  className="custom-select px-4"
                  style={{ height: "47px" }}
                  onChange={this.handleInput}
                  required
                >
                  <option>Select Travel Mode</option>
                  <option value="Train">Train</option>
                  <option value="Bus">Bus</option>
                  <option value="Flight">Flight</option>
                  <option value="Cab">Private Cab</option>
                </select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="name"
                  type="text"
                  className="form-control p-4"
                  placeholder="Your Name"
                  value={name}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="phone"
                  type="phone"
                  className="form-control p-4"
                  placeholder="Phone Number"
                  value={phone}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  onChange={this.handleInput}
                  name="email"
                  type="email"
                  className="form-control p-4"
                  placeholder="Email (optional)"
                  value={email}
                />
              </Form.Group>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  // onClick={() => {
                  //   this.setState({
                  //     loading: true,
                  //   });
                  // }}
                  onClick={this.props.toggleModal}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  value="Submit"
                  type="submit"
                  // onClick={this.handleSubmit}
                >
                  Get a call back
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default FormModal;
