import React, { Component } from "react";
import About from "./About";
import Booking from "./Booking";
import Carousel from "./Carousel";
import "./css/style.css";
import Destination from "./Destination";
import Feature from "./Feature";
import Footer from "./Footer";

import NavBar from "./NavBar";
import Service from "./Service";
import Testimonial from "./Testimonial";
import TopBar from "./TopBar";

class Home extends Component {
  render() {
    return (
      <div>
        {/* <!-- Topbar Start --> */}
        {/* <TopBar /> */}
        {/* <!-- Topbar End --> */}

        {/* <!-- Navbar Start --> */}
        <NavBar />
        {/* <!-- Navbar End --> */}

        {/* <!-- Carousel Start --> */}
        <Carousel />
        {/* <!-- Carousel End --> */}

        {/* <!-- Booking Start --> */}
        <Booking />
        {/* <!-- Booking End --> */}

        {/* <!-- About Start --> */}
        <About />
        {/* <!-- About End --> */}

        {/* <!-- Feature Start --> */}
        <Feature />
        {/* <!-- Feature End --> */}

        {/* <!-- Destination Start --> */}
        <Destination />
        {/* <!-- Destination Start --> */}

        {/* <!-- Service Start --> */}
        <Service />
        {/* <!-- Service End --> */}

        {/* <!-- Testimonial Start --> */}
        <Testimonial />
        {/* <!-- Testimonial End --> */}

        {/* <!-- Footer Start --> */}
        <Footer />
        {/* <!-- Footer End --> */}

        {/* <!-- Back to Top --> */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i className="fa fa-angle-double-up"></i>
        </a>
      </div>
    );
  }
}

export default Home;
