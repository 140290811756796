import Delhi from "../img/destinations/Delhi.jpg";
import Gangtok from "../img/destinations/Gangtok.jpg";
import Kerala from "../img/destinations/Kerala.jpg";
import Punjab from "../img/destinations/Punjab.jpg";
import Rajasthan from "../img/destinations/Rajasthan.jpg";
import Hyderabad from "../img/destinations/Hyderabad.jpg";

import testimonial1 from "../img/testimonial-1.jpg";
import testimonial2 from "../img/testimonial-2.jpg";
import testimonial3 from "../img/testimonial-3.jpg";
import testimonial4 from "../img/testimonial-4.jpg";

const jsonData = {
  companyName: "ERGON TRAVELS",
  email: "info@ergontravels.com",
  phone: "+91 9768340224",
  companyName1: "ERGON",
  companyName2: "TRAVELS",
  agentName: "Hritik Halkara",

  tagline: "Your pocket friendly travel buddy",

  developerLink: "https://www.linkedin.com/in/rajupotharaju155",

  carouselData: [
    {
      id: "1",
      city: "Hyderabad",
      image: Hyderabad,
      description: "A Bliss of Biryani and Charishma of Charminar",
    },
    {
      id: "2",
      city: "Rajasthan",
      image: Rajasthan,
      description: "Padharo Mhare Desh!",
    },
    {
      id: "3",
      city: "Kerala",
      image: Kerala,
      description: "Gateway to south India",
    },
    {
      id: "4",
      city: "Delhi",
      image: Delhi,
      description:
        "A city which has a great history and was a witness to many great reigns",
    },
  ],

  aboutUs: {
    title: "We Provide Best Tour Packages In Your Budget",
    description:
      "Dolores lorem lorem ipsum sit et ipsum. Sadip sea amet diam\
                dolore sed et. Sit rebum labore sit sit ut vero no sit. Et elitr\
                stet dolor sed sit et sed ipsum et kasd ut. Erat duo eos et erat\
                sed diam duo",
  },

  feature: [
    {
      id: "1",
      title: "Competitive Pricing",
      description:
        "Price that makes Ergon travels your Pocket Friendly Friendly travel buddy",
    },
    {
      id: "2",
      title: "Best Services",
      description:
        "Ergon travels is available 24x7 at your service, Hotel Booking, Train booking, you name it we have it",
    },
    {
      id: "3",
      title: "India Wide Coverage",
      description:
        "From Kashmir to Kanyakumari, we serve everywhere, from booking to boarding, we are with you",
    },
  ],

  destination: [
    {
      id: "1",
      image: Delhi,
      title: "Delhi",
      description: "",
    },
    {
      id: "2",
      image: Gangtok,
      title: "Gangtok",
      description: "",
    },
    {
      id: "3",
      image: Kerala,
      title: "Kerala",
      description: "",
    },
    {
      id: "4",
      image: Punjab,
      title: "Punjab",
      description: "",
    },
    {
      id: "5",
      image: Rajasthan,
      title: "Rajasthan",
      description: "",
    },
    {
      id: "6",
      image: Hyderabad,
      title: "Hyderabad",
      description: "",
    },
  ],

  services: [
    {
      id: "1",
      icon: "fa-route",
      title: "Travel Guide",
      description:
        "Our itineraries include all rail, sea and road transport as part of the itinerary so you can enjoy tension free",
    },
    {
      id: "2",
      icon: "fa-ticket-alt",
      title: "Ticket Booking",
      description:
        "We know Comfortable journeys are equally important, enjoy hassle free confirm booking from Ergon travels",
    },
    {
      id: "3",
      icon: "fa-hotel",
      title: "Hotel Booking",
      description:
        "Comfortable & convenient hotels cherry picked by our hotel management team",
    },
  ],

  testimonials: [
    {
      id: "1",
      image: testimonial1,
      clientName: "Raju Potharaju",
      clientTestimony:
        "One of the best and affordable travel agency in the city, confirm tickets all the time",
    },
    {
      id: "2",
      image: testimonial2,
      clientName: "Komal Swain",
      clientTestimony:
        "Best tour packages at best prices, my Manali journey has been amazing with Ergon Travels",
    },
    {
      id: "3",
      image: testimonial3,
      clientName: "Amisha Chachad",
      clientTestimony:
        "I recommen Ergon travels for all urgent bookings, their on call support from booking to boarding is amazing",
    },
  ],
};

export default jsonData;
