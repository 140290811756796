import React from "react";
import jsonData from "./data/jsondata";

function Destination() {
  const destinationsWidgets = jsonData.destination.map((dest, index) => {
    return (
      <div key={index} className="col-lg-4 col-md-6 mb-4">
        <div className="destination-item position-relative overflow-hidden mb-2">
          <img className="img-fluid" src={dest.image} alt="" />
          <a
            className="destination-overlay text-white text-decoration-none"
            href=""
          >
            <h5 className="text-white">{dest.title}</h5>
            <span>{dest.description}</span>
          </a>
        </div>
      </div>
    );
  });
  return (
    <div id="destination" className="container-fluid py-1">
      <div className="container pt-5 pb-3">
        <div className="text-center mb-3 pb-3">
          <h6
            className="text-primary text-uppercase"
            style={{ letterSpacing: "5px" }}
          >
            Destination
          </h6>
          <h1>Explore Top Destination</h1>
        </div>
        <div className="row">{destinationsWidgets}</div>
      </div>
    </div>
  );
}

export default Destination;
