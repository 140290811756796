import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function Loader(props) {
  console.log(props);
  return (
    <Modal
      show={props.show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ textAlign: "center" }}>
        <p>Just a sec...</p>
        <Spinner animation="border" variant="success" />
      </Modal.Body>
    </Modal>
  );
}

export default Loader;
