import React from "react";
import jsonData from "./data/jsondata";

function Service() {
  const servicesWidget = jsonData.services.map((service, index) => {
    return (
      <div key={index} className="col-lg-4 col-md-6 mb-4">
        <div className="service-item bg-white text-center mb-2 py-5 px-4">
          <i className={`fa fa-2x ${service.icon} mx-auto mb-4`}></i>
          <h5 className="mb-2">{service.title}</h5>
          <p className="m-0">{service.description}</p>
        </div>
      </div>
    );
  });
  return (
    <div id="service" className="container-fluid py-1">
      <div className="container pt-5 pb-3">
        <div className="text-center mb-3 pb-3">
          <h6
            className="text-primary text-uppercase"
            style={{ letterSpacing: "5px" }}
          >
            Services
          </h6>
          <h1>Tours & Travel Services</h1>
        </div>
        <div className="row">{servicesWidget}</div>
      </div>
    </div>
  );
}

export default Service;
