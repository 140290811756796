import React from "react";
import jsonData from "./data/jsondata.js";

function Carousel() {
  const carouselWidgets = jsonData.carouselData.map((dest, index) => {
    return (
      <div
        key={index}
        className={`carousel-item ${index == 0 ? "active" : ""}`}
      >
        <img className="w-100 c-image" src={dest.image} alt="Image" />
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div className="p-3" style={{ maxWidth: "900px" }}>
            <h4 className="text-white text-uppercase mb-md-3">
              {dest.description}
            </h4>
            <h1 className="display-3 text-white mb-md-4">{dest.city}</h1>
            <a href="/" className="btn btn-primary py-md-3 px-md-5 mt-2">
              Book Now
            </a>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="container-fluid p-0">
      <div id="header-carousel" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">{carouselWidgets}</div>
        <a
          className="carousel-control-prev"
          href="#header-carousel"
          data-slide="prev"
        >
          <div
            className="btn btn-dark"
            style={{ width: "45px", height: "45px" }}
          >
            <span className="carousel-control-prev-icon mb-n2"></span>
          </div>
        </a>
        <a
          className="carousel-control-next"
          href="#header-carousel"
          data-slide="next"
        >
          <div
            className="btn btn-dark"
            style={{ width: "45px", height: "45px" }}
          >
            <span className="carousel-control-next-icon mb-n2"></span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Carousel;
