import React, { Component } from "react";
import FormModal from "./Modal/FormModal";

export class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      from: "",
      to: "",
      departureDate: "",
    };
  }

  toggleModal = () => {
    console.log("Toggle clicked");
    console.log(this.state.showModal);
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const modal = this.state.showModal ? (
      <FormModal
        show={this.state.showModal}
        from={this.state.from}
        to={this.state.to}
        departureDate={this.state.departureDate}
        toggleModal={this.toggleModal}
      />
    ) : null;
    const { from, to, departureDate } = this.state;
    return (
      <div id="contact" className="container-fluid booking mt-5 pb-5">
        {modal}
        <div className="container pb-5">
          <div className="bg-light shadow" style={{ padding: "30px" }}>
            <div
              className="row align-items-center"
              style={{ minHeight: "60px" }}
            >
              <div className="col">
                <h4>Enquire form</h4>
                <p>We'll get back to you shortly</p>
                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-3 mb-md-0">
                      <input
                        value={from}
                        onChange={this.handleInput}
                        name="from"
                        type="text"
                        className="form-control p-4"
                        placeholder="From Eg. Mumbai/Chennai"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3 mb-md-0">
                      <input
                        value={to}
                        onChange={this.handleInput}
                        name="to"
                        type="text"
                        className="form-control p-4"
                        placeholder="To Eg. Delhi/Manali"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3 mb-md-0">
                      <div
                        className="date"
                        id="date1"
                        data-target-input="nearest"
                      >
                        <input
                          onChange={this.handleInput}
                          value={departureDate}
                          name="departureDate"
                          type="date"
                          className="form-control p-4 datetimepicker-input"
                          placeholder="Depart Date"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-2">
                    <div className="mb-3 mb-md-0">
                      <select
                        placeholder="Select Mode"
                        className="custom-select px-4"
                        style={{ height: "47px" }}
                      >
                        <option value="1">Travel Mode</option>
                        <option value="2">Train</option>
                        <option value="3">Bus</option>
                        <option value="4">Flight</option>
                        <option value="5">Private Cab</option>
                      </select>
                    </div>
                  </div> */}
                  {/* <div className="col-md-2">
                    <div className="mb-3 mb-md-0">
                      <input
                        type="text"
                        className="form-control p-4"
                        placeholder="No. of Travellers"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                      style={{ height: "47px", marginTop: "-2px" }}
                      onClick={() => this.toggleModal()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Booking;

// function Booking() {

// }

// export default Booking;
